.website-container__text-container {
  text-align: center;
}

.website-container__image-container {
  /* margin-bottom: 3rem; */
  width: 400px;
  margin: 0 auto 3rem auto;
}

.website-container__website-image {
  position: absolute;
  margin-top: 12px;
  margin-left: 13px;
  width: 375px;
}

.website-container__spinner {
  position: absolute;
  margin-top: 16px;
  margin-left: 17px;
  width: 375px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-container__monitor-image {
  width: 100%;
}

.website-container__text-container p {
  margin-bottom: 3rem;
}

.website-container__arrow-container {
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.website-container__arrow-container svg {
  cursor: pointer;
  width: 150px;
  height: 100px;
  transition-property: color;
  transition-duration: 0.5s;
}

.website-container__arrow-container svg:hover {
  color: gray;
}

/* Md */
@media only screen and (max-device-width: 900px) {
  .website-container__arrow-container {
    color: black;
  }
}

@media only screen and (max-device-width: 600px) {
  .website-container__website-image {
    margin-top: 9px;
    margin-left: 9px;
    width: 242px;
  }

  .website-container__spinner {
    margin-left: 125px;
    margin-top: 35px;
    width: auto;
    align-items: flex-start;
  }

  .website-container__monitor-image {
    width: 260px;
  }
  .website-container__arrow-container svg {
    margin-top: 2rem;
    width: 120px;
    height: 80px;
  }
  .website-container__image-container {
    width: 320px;
    margin: 2rem auto;
  }
}
