.contact {
  display: flex;
}

.contact__right-container {
  width: 49vw;
}

.contact__right-text-container {
  margin: 4rem;
}

.contact__right-text-container h6 {
  margin: 2rem 0;
}

.contact__right-text-container a {
  text-decoration: none;
  color: inherit;
}

.contact__right-text-container a:hover {
  color: grey;
}

/* Md */
@media only screen and (max-device-width: 900px) {
  .contact__right-container {
    width: 100vw;
  }
  .contact {
    flex-wrap: wrap;
  }
}

@media only screen and (max-device-width: 600px) {
  .contact__right-text-container {
    margin: 2rem;
  }
}
