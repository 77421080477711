@keyframes fadeColor {
  from {
    background-color: #2e645a;
  }

  to {
    background-color: #16302b;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home {
  height: 100vh;
  width: 100vw;
  background-color: #16302b;
  display: flex;
  animation-duration: 1s;
  animation-name: fadeColor;
}

.home__container {
  width: 500px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.home__container h6 {
  font-weight: bold;
  animation-duration: 1s;
  animation-name: fadeIn;
}

.home__logo-container {
  /* display: flex; */
  height: 30vh;
  align-items: flex-end;
}

.home__logo-container h1 {
  font-size: 3.5rem;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  opacity: 0;
}

.home__logo-container img {
  width: 270px;
  margin-bottom: 2rem;
}
.home__menu-container {
  height: 30vh;
  display: flex;
  align-items: flex-end;
  min-width: 300px;
  margin-left: -2rem;
}
