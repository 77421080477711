.about {
  display: flex;
  /* flex-wrap: wrap; */
}

.about__right-section {
  max-width: 49vw;
}

.about__right-text-container {
  margin: 5rem 3rem;
}

/* Md */
@media only screen and (max-device-width: 900px) {
  .about__right-section {
    width: 100vw;
    max-width: none;
    /* background-color: #16302b;
    color: white; */
  }
  .about {
    flex-wrap: wrap;
  }
}

@media only screen and (max-device-width: 600px) {
  .about__right-text-container {
    margin: 2rem;
  }
}
