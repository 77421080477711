.websites {
  display: flex;
}

.websites__right-container {
  min-width: 49vw;
  height: 100vh;
  background-color: #16302b;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Md */
@media only screen and (max-device-width: 900px) {
  .websites {
    flex-wrap: wrap;
  }
  .websites__right-container {
    width: 100vw;
    min-width: none;
    background-color: white;
    color: black;
  }
}
