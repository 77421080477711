.mobile-menu__button {
  position: fixed;
  display: none;
  top: 2rem;
  left: 2rem;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: #f7484e;
  cursor: pointer;
  z-index: 2;
}

.mobile-menu__button svg {
  padding: 3px;
  height: 30px;
  width: 30px;
  color: white;
  position: absolute;
}

.mobile-menu__main {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.mobile-menu__main ul {
  list-style: none;
}

.mobile-menu__main ul li {
  margin: 1rem 0;
  cursor: pointer;
}

.mobile-menu__main ul li p {
  font-weight: bold;
}
/* Md */
@media only screen and (max-device-width: 900px) {
  .mobile-menu__button {
    display: block;
  }
}
