.side-bar {
  height: 100vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.side-bar h1 {
  margin-top: 40vh;
}

.side-bar p {
  margin-top: 1rem;
}

.side-bar__menu-container {
  width: 300px;
  margin-left: -5rem;
  margin-bottom: 2rem;
}

.primary-background {
  background-color: #f7484e;
}

.secondary-background {
  background-color: #16302b;
}

.white-background {
  background-color: white;
}

.black-color {
  color: black;
}

.white-color {
  color: white;
}

/* Md */
@media only screen and (max-device-width: 900px) {
  .side-bar {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    position: static;
  }
  .side-bar h1 {
    margin-top: 0;
    font-size: 5rem;
  }
  .side-bar__menu-container {
    display: none;
  }

  .mobile-primary-background {
    background-color: #f7484e;
  }

  .mobile-secondary-background {
    background-color: #16302b;
  }

  .mobile-white-background {
    background-color: white;
  }

  .mobile-black-color {
    color: black;
  }

  .mobile-white-color {
    color: white;
  }
}

@media only screen and (max-device-width: 600px) {
  .side-bar h1 {
    font-size: 4rem;
  }
}
