.menu__container ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu__link {
  cursor: pointer;
}

.menu__link:hover {
  height: 20px;
  padding-bottom: 5px;
  border-bottom: solid white 2px;
}

.menu__link-primary {
  cursor: pointer;
}

.menu__link-primary:hover {
  height: 20px;
  padding-bottom: 5px;
  border-bottom: solid #f7484e 2px;
}
